
import { Component, Vue } from "vue-property-decorator";
import _ from "underscore";
import * as rhovanion from "@/api/rhovanion";
import DeletePopover from "@/components/DeletePopover.vue";
import Device from "@/models/Device";
import DeviceData from "@/models/Inventory/DeviceData";
import DeviceModel from "@/models/Inventory/DeviceModel";
import InventoryDrawer from "../../components/inventory/TheInventoryDrawer.vue";
import DeviceDataForm from "../../components/inventory/DeviceDataForm.vue";
import DeviceModelForm from "../../components/inventory/DeviceModelForm.vue";
import Notify from "@/utils/notifications";

interface SortParams {
  column: Record<string, unknown>;
  order: string | null;
  prop: string;
}

interface Query {
  serialNumber: string | null;
  location: string | null;
  brand: string | null;
  category: string | null;
  assigned: string | null;
}

@Component({
  components: {
    DeletePopover,
    InventoryDrawer,
    DeviceDataForm,
    DeviceModelForm,
  },
})
export default class InventoryDevices extends Vue {
  search = "";
  fullscreenLoading = false;
  devicesLoading = true;
  deviceManageLoading = true;
  limitArray = [10, 25, 50, 100, 250, 500, 1000];
  batch = false;
  create = false;
  deviceDataChanged = false;
  deviceDrawer = false;
  createDeviceData = false;
  newDeviceData = false;
  newDeviceBrand = false;
  newDeviceModel = false;
  newBrand = false;
  deviceDataId = 0;
  //idCategory = 0;
  idCategory: number | null = null;
  device = new Device();
  deviceCategory: any;
  deviceData = new DeviceData();
  deviceModel = new DeviceModel();
  readonly defaultParams: rhovanion.Params<Query> = {
    query: {
      serialNumber: null,
      location: null,
      brand: null,
      category: null,
      assigned: null,
    },
    limit: 10,
    offset: 0,
    sort: null,
  };
  params = _.clone(this.defaultParams);
  currentPage = 1;
  devices = {} as rhovanion.Payload<rhovanion.Device>;
  devicesData = {} as rhovanion.Payload<rhovanion.DeviceCategory>;
  locations = {} as rhovanion.Payload<rhovanion.DeviceLocation>;
  brands: rhovanion.DeviceBrand[] = [];
  categories = {} as rhovanion.Payload<rhovanion.DeviceCategory>;
  models = {} as rhovanion.Payload<rhovanion.Model>;
  dialogManageVisible = false;
  dialogBrandVisible = false;
  dialogModelVisible = false;
  dialogManageTitle = "";
  // Assign devices
  devicesSelected: Array<number> = [];
  userSelectedId: number | null = null;
  locationSelectedId: number | null = null;
  addAction = false;
  dialogVisible = false;
  dialogTitle = "";
  users = {} as rhovanion.Payload<rhovanion.User>;
  assignable = false;
  unassignable = false;
  async mounted() {
    this.locations = await rhovanion.findDeviceLocation({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
    this.brands = await rhovanion.findDeviceBrand({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
    this.categories = await rhovanion.findDeviceCategory({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
    this.getDevices();
    this.users = await rhovanion.findUsers({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
  }

  created() {
    const index = "7.1";
    const title = "Dispositivos";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Inventario",
        to: {
          name: "inventory-devices",
        },
      },
      {
        text: "Dispositivos",
      },
    ]);
  }

  async getDevices(page = this.currentPage) {
    this.devicesLoading = true;
    this.currentPage = page;
    this.params.offset = (this.currentPage - 1) * this.params.limit;
    this.devices = await rhovanion.findDevice({
      ...this.params.query,
      limit: this.params.limit,
      offset: this.params.offset,
      sort: this.params.sort,
    });
    this.devicesLoading = false;
  }

  getDevicesData() {
    if (this.dialogManageTitle == "categoría") this.getCategories();
    else if (this.dialogManageTitle == "ubicación") this.getLocations();
    else if (this.dialogManageTitle == "estado") this.getStatus();
    else if (this.dialogManageTitle == "marca") this.getBrands();
    this.deviceDataChanged = !this.deviceDataChanged;
  }

  async getCategories() {
    this.deviceManageLoading = true;
    this.devicesData = await rhovanion.findDeviceCategory();
    this.deviceManageLoading = false;
  }

  async getLocations() {
    this.deviceManageLoading = true;
    this.devicesData = _.extend(this.devicesData, {
      rows: this.devicesData = await rhovanion.findDeviceLocation({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        sort: this.params.sort,
      }),
    });
    this.deviceManageLoading = false;
  }

  async getStatus() {
    this.deviceManageLoading = true;
    this.devicesData = _.extend(this.devicesData, {
      rows: this.devicesData = await rhovanion.findDeviceStatus({
        ...this.params.query,
        limit: this.params.limit,
        offset: this.params.offset,
        sort: this.params.sort,
      }),
    });
    this.deviceManageLoading = false;
  }

  async getBrands() {
    this.deviceManageLoading = true;
    this.brands = _.extend(this.brands, {
      rows: this.brands = await rhovanion.findDeviceBrand(),
    });
    this.deviceManageLoading = false;
  }

  async getModels() {
    this.deviceManageLoading = true;
    this.models = await rhovanion.findModels(this.idCategory!);
    this.deviceCategory = _.filter(this.devicesData.rows, (category: any) => {
      return category.id === this.idCategory;
    })[0];
    this.deviceManageLoading = false;
  }

  async getFilteredDevices() {
    this.devicesLoading = true;
    this.devices = await rhovanion.findDevice({
      ...this.params.query,
      limit: this.params.limit,
      offset: this.params.offset,
      sort: this.params.sort,
    });
    this.devicesLoading = false;
  }

  async sortDevicesDataTable(params: SortParams) {
    if (params.order === null) {
      this.params.sort = null;
    } else {
      this.params.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getDevices();
  }

  async sortTable(params: SortParams) {
    if (params.order === null) {
      this.params.sort = null;
    } else {
      this.params.sort = `${params.order === "descending" ? "-" : "+"}${
        params.prop
      }`;
    }
    await this.getDevices();
  }

  // En caso de que sea limpiado borrando
  nullSerialNumber(value: string | number) {
    if (value === "" && this.params.query !== undefined) {
      this.params.query.serialNumber = null;
    }
  }

  newDrawer() {
    this.create = true;
    this.device = new Device();
    this.deviceDrawer = true;
  }

  newDeviceDataForm() {
    this.newDeviceData = this.createDeviceData = true;
    this.deviceData = new DeviceData();
  }

  newDeviceBrandForm() {
    this.newDeviceBrand = this.createDeviceData = true;
    this.deviceData = new DeviceData();
  }

  newDeviceModelForm() {
    this.newDeviceModel = this.createDeviceData = true;
    this.deviceModel = new DeviceModel();
  }

  editDrawer(index: number) {
    this.create = false;
    const currentDevice = this.devices.rows[index];
    // <----->
    this.device = {
      id: currentDevice.id,
      "serial-number": currentDevice["serial-number"],
      features: currentDevice.features,
      "device-status-id": currentDevice.status.id,
      "device-location-id": currentDevice.location.id,
      observations: currentDevice.observations,
      "purchase-value": currentDevice["purchase-value"],
      "purchase-date": currentDevice["purchase-date"],
      "device-model-id": currentDevice.model.id,
      "device-category-id": currentDevice.model.category.id,
    };
    // <----->
    this.deviceDrawer = true;
  }

  deleteDevice(id: number) {
    this.devicesLoading = true;
    rhovanion
      .destroyDevice(id)
      .then(res => {
        this.getDevices();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.devicesLoading = false;
      });
  }

  deleteDeviceData(id: number) {
    if (this.dialogManageTitle == "categoría") this.deleteDeviceCategory(id);
    if (this.dialogManageTitle == "ubicación") this.deleteDeviceLocation(id);
    if (this.dialogManageTitle == "estado") this.deleteDeviceStatus(id);
    if (this.dialogManageTitle == "marca") this.deleteDeviceBrand(id);
    if (this.dialogManageTitle == "modelo") this.deleteDeviceModel(id);
  }

  deleteDeviceCategory(id: number) {
    this.deviceManageLoading = true;
    rhovanion
      .destroyDeviceCategory(id)
      .then(res => {
        this.getDevicesData();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.deviceManageLoading = true;
      });
  }

  deleteDeviceLocation(id: number) {
    this.deviceManageLoading = true;
    rhovanion
      .destroyDeviceLocation(id)
      .then(res => {
        this.getDevicesData();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.deviceManageLoading = true;
      });
  }

  deleteDeviceStatus(id: number) {
    this.deviceManageLoading = true;
    rhovanion
      .destroyDeviceStatus(id)
      .then(res => {
        this.getDevicesData();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.deviceManageLoading = true;
      });
  }

  deleteDeviceBrand(id: number) {
    this.deviceManageLoading = true;
    rhovanion
      .destroyDeviceBrand(id)
      .then(res => {
        this.getBrands();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.deviceManageLoading = true;
      });
  }

  deleteDeviceModel(id: number) {
    this.deviceManageLoading = true;
    rhovanion
      .destroyDeviceModel(id)
      .then(res => {
        this.getModels();
        Notify.successful(`${res.id} eliminado exitosamente.`);
      })
      .catch(error => {
        Notify.gebServerError(error);
      })
      .finally(() => {
        this.deviceManageLoading = true;
      });
  }

  editDeviceData(index: number) {
    this.createDeviceData = false;
    let currentDeviceData;
    if (this.dialogManageTitle == "marca") {
      this.newDeviceBrand = true;
      currentDeviceData = this.brands.rows[index];
    } else {
      currentDeviceData = this.devicesData.rows[index];
      this.newDeviceData = true;
    }
    this.deviceDataId = currentDeviceData.id;
    this.deviceData = {
      name: currentDeviceData.name,
      code: currentDeviceData.code,
    };
  }

  editModel(index: number) {
    this.createDeviceData = false;
    const currentDeviceData = this.models.rows[index];
    this.newDeviceModel = true;
    this.deviceDataId = currentDeviceData.id;
    this.deviceModel = {
      name: currentDeviceData.name,
      "device-brand-id": currentDeviceData.brand.id,
    };
  }

  assignUser() {
    if (this.userSelectedId !== null) {
      this.devicesSelected.map(id => {
        rhovanion
          .assignDevice(id, this.userSelectedId as number)
          .then(() => {
            this.getDevices();
            Notify.successful(`Operación realizada exitosamente.`);
          })
          .catch((error: any) => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDialog();
            this.userSelectedId = null;
          });
      });
    }

    if (this.locationSelectedId !== null) {
      this.devicesSelected.map(id => {
        const currentDevice = this.devices.rows.find(
          device => device.id === id
        ) as rhovanion.Device;
        rhovanion
          .updateDevice(id, {
            "device-status-id": currentDevice.status.id,
            "device-location-id": this.locationSelectedId as number,
            "device-model-id": currentDevice.model.id,
          })
          .then(() => {
            this.getDevices();
            Notify.successful(`Operación realizada exitosamente.`);
          })
          .catch((error: any) => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDialog();
            this.locationSelectedId = null;
          });
      });
    }
  }

  unassignUser() {
    if (this.userSelectedId === null) {
      this.devicesSelected.map(id => {
        rhovanion
          .unassignDevice(id)
          .then(() => {
            this.getDevices();
            Notify.successful(`Operación realizada exitosamente.`);
          })
          .catch((error: any) => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.closeDialog();
          });
      });
    }
  }

  selectionChange(val: Array<rhovanion.Device>) {
    this.devicesSelected = _.pluck(val, "id");
    this.unassignable = false;
    this.assignable = false;
    if (val.every(device => device.assigned === true)) {
      this.unassignable = true;
      this.assignable = false;
    }

    if (val.every(device => device.assigned === false)) {
      this.unassignable = false;
      this.assignable = true;
    }
  }

  manage(action: string) {
    this.search = "";
    switch (action) {
      case "assign":
        this.addAction = true;
        this.dialogVisible = true;
        this.dialogTitle = "Asignar dispositivos";
        break;
      case "unassign":
        this.addAction = false;
        this.dialogVisible = true;
        this.dialogTitle = "Desasignar dispositivos";
        break;
      case "create":
        this.newDrawer();
        break;
      case "categories":
        this.getCategories();
        this.dialogManageVisible = true;
        this.dialogManageTitle = "categoría";
        break;
      case "locations":
        this.getLocations();
        this.dialogManageVisible = true;
        this.dialogManageTitle = "ubicación";
        break;
      case "status":
        this.getStatus();
        this.dialogManageVisible = true;
        this.dialogManageTitle = "estado";
        break;
      case "brands":
        this.getBrands();
        this.dialogBrandVisible = true;
        this.dialogManageTitle = "marca";
        break;
      case "models":
        this.getCategories();
        this.getModels();
        this.dialogModelVisible = true;
        this.dialogManageTitle = "modelo";
    }
  }

  closeDialog() {
    this.dialogVisible = this.dialogManageVisible = this.dialogBrandVisible = this.dialogModelVisible = false;
    this.userSelectedId = null;
  }

  closeDrawer(value: boolean) {
    this.deviceDrawer = value;
  }

  fullName(index: number): string {
    return `${this.users.rows[index].name} ${this.users.rows[index].lastname}`;
  }

  deleteNamer(id: number): string {
    const device = this.devices.rows.find(device => device.id === id);
    let name = `${device?.model.name}`;
    if (device?.["serial-number"]) name += `-${device["serial-number"]}`;

    name += `-${device?.id}`;

    return name;
  }

  deleteDeviceDataNamer(id: number): string {
    let deviceData;
    if (this.dialogManageTitle == "marca") {
      deviceData = this.brands.rows.find((brand: any) => brand.id === id);
    } else if (this.dialogManageTitle == "modelo") {
      deviceData = this.models.rows.find(device => device.id === id);
    } else deviceData = this.devicesData.rows.find(device => device.id === id);
    const name = `${deviceData?.name}`;
    return name;
  }
}
