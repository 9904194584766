
import { Component, Prop, Vue } from "vue-property-decorator";
import * as rhovanion from "@/api/rhovanion";
import Notify from "@/utils/notifications";
import _ from "underscore";

@Component
export default class DeviceModelForm extends Vue {
  @Prop() deviceModel!: rhovanion.Model;
  @Prop() title!: string;
  @Prop() deviceCategory!: rhovanion.DeviceCategory;
  @Prop() create!: boolean;
  @Prop() deviceModelId!: number;

  brands: rhovanion.DeviceBrand[] = [];
  loading = false;
  rules = {
    name: [
      {
        required: false,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
    ],
    "device-brand-id": [
      {
        required: true,
        message: "Por favor seleccionar 1 opcion",
        trigger: "change",
      },
    ],
  };

  async getBrands() {
    this.brands = await rhovanion.findDeviceBrand({
      limit: 0,
      offset: 0,
      sort: "+name",
    });
  }

  createModel() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        this.deviceModel = _.extend(this.deviceModel, {
          deviceCategoryId: this.deviceCategory.id,
        });
        rhovanion
          .createDeviceModel(this.deviceModel)
          .then(async res => {
            this.$emit("newDeviceModel");
            Notify.successful("Modelo creado exitosamente.");
            this.close();
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.close();
          });
      }
    });
  }

  updateModel() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        rhovanion
          .updateDeviceModel(this.deviceModelId, this.deviceModel)
          .then(async res => {
            this.$emit("newDeviceModel");
            Notify.successful(`El modelo se actualizó exitosamente.`);
            this.close();
          })
          .catch(error => {
            Notify.gebServerError(error);
          })
          .finally(() => {
            this.close();
          });
      }
    });
  }

  close() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    (this.$refs["form"] as HTMLFormElement).resetFields();
    this.$emit("hideDeviceModelForm", false);
  }

  async mounted() {
    this.getBrands();
  }
}
