
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as rhovanion from "@/api/rhovanion";
import Notify from "@/utils/notifications";

@Component
export default class DeviceDataForm extends Vue {
  @Prop() deviceData!: rhovanion.DeviceCategory;
  @Prop() data!: string;
  @Prop() create!: boolean;
  @Prop() deviceDataId?: number;
  loading = false;
  rules = {
    name: [
      {
        required: true,
        message: "El nombre es obligatorio.",
        trigger: "blur",
      },
    ],
    code: [
      {
        required: this.data === "model" ? false : true,
        message: "El código es obligatorio.",
        trigger: "blur",
      },
      {
        pattern: /^[A-Z_]+$/,
        message: "El código debe estar en formato SNAKE_CASE en mayúsculas.",
        trigger: "blur",
      },
    ],
  };

  createDeviceData() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        switch (this.data) {
          case "categoría":
            rhovanion
              .createDeviceCategory(this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} creado exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "ubicación":
            rhovanion
              .createDeviceLocation(this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} creado exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "estado":
            rhovanion
              .createDeviceStatus(this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} creado exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "marca":
            rhovanion
              .createDeviceBrand(this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} creada exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
        }
      }
    });
  }

  updateDeviceData() {
    this.loading = true;
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) {
        this.loading = false;
        return false;
      } else {
        switch (this.data) {
          case "categoría":
            rhovanion
              .updateDeviceCategory(this.deviceDataId!, this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} se actualizó exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "ubicación":
            rhovanion
              .updateDeviceLocation(this.deviceDataId!, this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} se actualizó exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "estado":
            rhovanion
              .updateDeviceStatus(this.deviceDataId!, this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} se actualizó exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
          case "marca":
            rhovanion
              .updateDeviceBrand(this.deviceDataId!, this.deviceData)
              .then(async res => {
                this.$emit("newDeviceData");
                Notify.successful(`${this.data} se actualizó exitosamente.`);
                this.close();
              })
              .catch(error => {
                Notify.gebServerError(error);
              })
              .finally(() => {
                this.close();
              });
            break;
        }
      }
    });
  }

  close() {
    this.loading = false;
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    (this.$refs["form"] as HTMLFormElement).resetFields();
    this.$emit("hideDeviceDataForm", false);
  }

  get title() {
    return this.create ? `Crear ${this.data}` : `Actualizar ${this.data}`;
  }
}
